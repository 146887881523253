<template>
<!-- 我的评论 -->
  <div id="app">
    <div class="center-tit">
      我的评论<span>/My Comments</span>
      <ul>
        <li
          v-for="(item, index) in tabList"
          :key="index"
          :class="idx == index ? 'active' : ''"
          @click="tabNav(index, item.status)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="list-con">
      <div v-for="(item, index) in list" :key="index" class="comment">
        <a :href="item.href">
          <div class="title">{{ item.title }}</div>
        </a>
        <div
          v-for="(childComments, cIndex) in item.children"
          :key="index + '-' + cIndex"
          class="childComments"
        >
          <a
            :href="`${item.href}&commentId=${childComments.id}&commentPage=${childComments.page}`"
          >
            <div class="childCommentText" v-if="!childComments.username">
              {{ childComments.content }}
            </div>
            <div class="childCommentText" v-else>
              你 回复了
              <span style="color: #333">{{ childComments.username }}</span>
              {{ childComments.content }}
            </div>
          </a>
          <div class="childCommentTime">{{ childComments.createTime }}</div>
        </div>
      </div>
    </div>

    <div style="text-align: center">
      <el-pagination
        :current-page.sync="currentPage"
        :total="total"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getComments } from "@/service/userCenter";
export default {
  data() {
    return {
      idx: 0,
      curStatus: "",
      tabList: [
        {
          status: "",
          name: "全部",
          checked: true,
        },
        {
          status: 0,
          name: "动态",
          checked: false,
        },
        {
          status: 1,
          name: "面经",
          checked: false,
        },
        {
          status: 2,
          name: "面试题",
          checked: false,
        },
        {
          status: 3,
          name: "倾听",
          checked: false,
        },

        {
          status: 4,
          name: "直播",
          checked: false,
        },
        {
          status: 5,
          name: "经感",
          checked: false,
        },
        {
          status: 6,
          name: "免费试听",
          checked: false,
        },
      ],
      dialogFormVisibleMoving: false,
      list: [],
      currentPage: 1, //当前页
      total: 0, //所有的数据
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.getComments();
  },
  methods: {
    reload() {
      window.location.reload();
    },
    tabNav(index, status) {
      this.currentPage = 1;
      this.total = 0;
      this.curStatus = status;
      this.tabList.forEach((element, i) => {
        if (i == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      this.idx = index;
      this.getComments();
    },

    getComments(page) {
      getComments(this.curStatus, page ? page : 1).then((res) => {
        if (res.success) {
          this.list = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    handleCurrentChange(val) {
      this.getComments(val);
    },
  },
};
</script>

<style scoped>
@import "../../../assets/css/center.css";
</style>

<style lang="scss" scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #fa6400;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #606266;
}

.c-title {
  text-align: center;
  padding: 20px;
}

.c-title div:nth-child(1) {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.c-title div:nth-child(2) {
  margin-top: 12px;
  font-weight: 400;
  color: #fa6400;
  cursor: pointer;
}

.c-btn {
  display: flex;
  margin-bottom: 20px;
  margin-left: 16px;
  margin-top: 30px;
}

.c-btn div {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 22px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  border-radius: 2px;
}

.c-btn div:nth-child(1) {
  color: white;
  background: linear-gradient(to left, #fd1d1d, #fcb045);
  margin-right: 20px;
  cursor: pointer;
}

.c-btn div:nth-child(2) {
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.comment {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 12px;
  .title {
    font-size: 26px;
    margin-bottom: 12px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    width: 80%;
    &:hover {
      text-decoration: underline;
    }
  }
}
.childComments {
  margin-bottom: 6px;
  .childCommentTime {
    float: right;
    color: #999;
  }
  .childCommentText {
    display: inline-block;
    width: 80%;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
